<template>
  <el-container id="order-content">
    <el-aside width="595px">
      <el-container id="trade">
        <el-header height="135px">
          <el-tabs v-model="tradeActiveTab" @tab-click="handleTabClick">
            <el-tab-pane label="待审核" name="checking" />
            <el-tab-pane label="已报单" name="checked" />
            <el-tab-pane label="已删除" name="deleted" />
          </el-tabs>
          <el-form :inline="true">
            <el-form-item>
              <el-input v-model="tableConfig.searchForm.data.keyword" placeholder="请输入查询内容" clearable @clear="handleQuery"
                @keydown.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item style="margin-right: 5px;">
              <el-date-picker v-model="tableConfig.searchForm.date" type="date" placeholder="选择日期"
                value-format="yyyy-MM-dd" @change="handleQuery" />
              <el-time-picker :disabled="!tableConfig.searchForm.date" is-range v-model="tableConfig.searchForm.time"
                range-separator="-" format='HH:mm' value-format="HH:mm:ss" :clearable="false">
              </el-time-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-refresh" @click="handleQuery">查询</el-button>
              <el-button type="primary" plain icon="el-icon-download" :loading="loading"
                @click="handleExport">导出</el-button>
            </el-form-item>
            <el-form-item>
              <el-switch v-model="tableConfig.searchForm.data.single" active-text="单选" />
            </el-form-item>
            <el-form-item>
              <el-switch v-model="autoSelectNext" active-text="自动选中第一个" />
            </el-form-item>
            <el-form-item>
              <el-switch v-if="$store.state.routes.systemSetting.WmsEnabled"
                v-model="$store.state.routes.systemSetting.ReportCreatePurchase" active-text="报单创建采购单"
                @change="$store.dispatch('routes/changeSettingItem', { ReportCreatePurchase: $store.state.routes.systemSetting.ReportCreatePurchase })" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round icon="el-icon-s-tools" @click="handleGetSetting">报单配置</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :tableConfig="tableConfig" :showCheckboxColumn="true" :showIndexColumn="false"
            :showLastEmptyColumn="false" :showPagination="false" @selectionChange="selectionChange"
            @handleRowClick="handleRowClick" @handleSelect="handleSelect" @handleSelectAll="handleSelectAll" />
        </el-main>
        <el-footer height="40px">
          <span>供应商：{{ statistics.supplierCount }}</span>
          <span>承接客户：{{ statistics.purchaserCount }}</span>
          <span>订单量：{{ statistics.preorderCount }}</span>
          <span>报单件数：{{ statistics.totalCount }}</span>
        </el-footer>
      </el-container>
    </el-aside>
    <el-main>
      <el-container id="detail" v-loading="detailLoading">
        <el-header height="auto" style="margin-bottom:10px;">
          <el-radio-group v-model="detailActiveTab" @change="tabChanged">
            <el-radio-button v-if="detailList.length <= 0" :label="-1">报单视图</el-radio-button>
            <el-radio-button v-for="(item, index) in detailList" :key="index" :label="index">{{ item.SupplierName +
              (item.ErrorMsg ? '[异常]' : '') }}</el-radio-button>
          </el-radio-group>
        </el-header>
        <el-main>
          <el-input v-model="selectSupplier.Content" type="textarea" />
          <edit-supplier ref="editSupplier" @handleChange="handleSupplierSettingSave" />
        </el-main>
        <el-footer height="40px">
          <div>
            <span>承接客户：{{ statistics.purchaserCountEx }}</span>
            <span>报单件数：{{ statistics.totalCountEx }}</span>
          </div>
          <div>
            <el-popover ref="popover" v-if="tradeActiveTab == 'checking'" class="btn_popover" placement="top"
              trigger="click" width="350">
              <el-input type="textarea" v-model="$store.state.routes.systemSetting.PreorderPostfix" :rows="5"
                resize="none" :style="{ width: '350px' }"></el-input>
              <el-button type="primary" style="margin-top:10px;float:right;" @click="handleSaveSetting">保存</el-button>
              <el-button slot="reference" icon="el-icon-edit-outline">报单后缀</el-button>
            </el-popover>
            <el-button v-if="detailActiveTab != '-1' && tableConfig.searchForm.data.state == 0"
              @click="handleSupplierSetting" icon="el-icon-edit" type="primary">设置</el-button>
            <el-button v-if="tableConfig.searchForm.data.state == 0" type="danger"
              @click="handleChangeState(2)">删除</el-button>
            <el-button v-if="tableConfig.searchForm.data.state == 0 && detailList.length <= 1" type="primary"
              @click="handleCopy('select', $event)">QQ复制</el-button>
            <el-dropdown v-if="tableConfig.searchForm.data.state == 0 && detailList.length > 1"
              style="margin: 0 10px;width: 90px;">
              <el-button type="primary">QQ复制<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="handleCopy('select', $event)">当前报单</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="text" @click="handleCopy('all', $event)">全部报单</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button v-if="tableConfig.searchForm.data.state == 0 && detailList.length <= 1" type="success"
              @click="handleSend('select', $event)" :loading="sendFlag">QQ发送</el-button>
            <el-dropdown v-if="tableConfig.searchForm.data.state == 0 && detailList.length > 1" style="width: 90px;">
              <el-button type="success">QQ发送<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="handleSend('select', $event)" :loading="sendFlag">当前报单</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="text" @click="handleSend('all', $event)" :loading="sendFlag">全部报单</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-switch v-if="tradeActiveTab != 'checking'" v-model="groupBySupplier" active-text="按供应商分组"
              @change="handleAnalyse" />
            <el-button v-if="tableConfig.searchForm.data.state != 0" type="danger"
              @click="handleChangeState(0)">撤回</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <helper-center ref="helperCenter" position="preorder" :visible="true"></helper-center>
    <el-dialog class="report-dialog" v-dialog-drag title="自动报单设置" width="400px" :visible="settingVisible"
      :close-on-click-modal="false" @close="settingVisible = false">
      <el-form :model="settingForm" label-width="90px">
        <el-form-item label="是否启用：">
          <el-switch v-model="settingForm.Enabled" />
        </el-form-item>
        <el-form-item label="报单时间：" v-if="settingForm.Enabled">
          <el-time-picker is-range v-model="settingForm.TimeRange" range-separator="-" format='HH:mm:ss'
            value-format="HH:mm:ss" :clearable="false">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="间隔时间：" v-if="settingForm.Enabled">
          <el-time-picker v-model="settingForm.Timespan" format='HH:mm:ss' value-format="HH:mm:ss"
            :maxTime="settingForm.maxTime" :clearable="false">
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="settingVisible = false">关闭</el-button>
        <el-button type="primary" size="small" @click="saveSettingChange">保存</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import clipboard from "@/utils/clipboard";
import { getColumnValue } from "@/utils/tool";
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';
import { botSend } from '@/utils/stallBot';
import EditSupplier from "@/views/purchase/components/editSupplier.vue";

export default {
  name: "StallBotPreorder",
  components: {
    HelperCenter,
    EditSupplier,
  },
  data() {
    return {
      autoSelectNext: false,
      tradeActiveTab: "checking",
      detailActiveTab: "-1",
      selectRows: [],
      selectRow: null,
      tableConfig: {
        url: "/api/stallBotPreorder/getList",
        rowKey: "Id",
        searchForm: {
          date: this.getDate(),
          time: ['00:00:00', '23:59:59'],
          data: {
            state: 0,
            keyword: "",
            single: false
          },
        },
        columns: [
          {
            label: "Id",
            prop: "Id",
            type: "text",
            isHidden: true,
          },
          {
            label: "供应商",
            prop: "SupplierName",
            type: "text",
          },
          {
            label: "客户名称",
            prop: "PurchaserNames",
            type: "text",
            width: "200px",
          },
          {
            label: "接单时间",
            prop: "CreateTime",
            type: "text-datetime",
            dateFormat: "<strong>yy-MM-dd</strong> HH:mm",
            width: "118px",
          },
          {
            label: "商品件数",
            prop: "TotalQty",
            type: "text",
            width: "90px",
          },
        ],
      },
      statistics: {
        supplierCount: 0,
        preorderCount: 0,
        purchaserCount: 0,
        totalCount: 0,
        purchaserCountEx: 0.0,
        totalCountEx: 0,
      },
      detailList: [],
      selectSupplier: {
        Ids: [],
        SupplierId: '',
        SupplierName: '',
        Content: '',
        ConfigData: {
          qqSetting: {
            qq: undefined,
            botQQ: undefined,
            type: undefined
          }
        },
        ErrorMsg: '',
      },
      detailLoading: false,
      sendFlag: false,
      loading: false,
      botQQList: [],
      changePurchase: false,
      settingForm: {
        TimeRange: ['12:00:00', '20:00:00'],
        Timespan: '00:00:00',
        Enabled: false
      },
      settingVisible: false,
      groupBySupplier: true
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.handleQuery();
      await this.getBotList();
    });
  },
  methods: {
    async handleSaveSetting() {
      await this.$store.dispatch('routes/changeSettingItem', { PreorderPostfix: this.$store.state.routes.systemSetting.PreorderPostfix });
      this.$baseMessage("保存成功", "success");
      this.handleAnalyse();
      this.$refs.popover.doClose();
    },
    tabChanged() {
      this.detailTabClick();
    },
    detailTabClick(idx) {
      idx = typeof (idx) == 'undefined' ? parseInt(this.detailActiveTab) : parseInt(idx);
      this.selectSupplier = this.detailList[idx];
      this.detailActiveTab = idx;
      if (typeof (this.selectSupplier.ConfigData) == 'undefined') {
        this.selectSupplier.ConfigData = {
          qqSetting: {
            qq: undefined,
            botQQ: undefined,
            type: undefined
          }
        };
      }
    },
    getDate() {
      const date = new Date();
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    async handleExport() {
      this.loading = true;
      if (this.tableConfig.searchForm.date) {
        let date = this.tableConfig.searchForm.date;
        if (this.tableConfig.searchForm.time && this.tableConfig.searchForm.time.length > 0) {
          this.tableConfig.searchForm.data.startTime = date + " " + this.tableConfig.searchForm.time[0];
          this.tableConfig.searchForm.data.endTime = date + " " + this.tableConfig.searchForm.time[1];
        }
        else {
          this.tableConfig.searchForm.data.startTime = date + " 00:00:00";
          this.tableConfig.searchForm.data.endTime = date + " 23:59:59";
        }
      }
      else {
        this.tableConfig.searchForm.data.startTime = null;
        this.tableConfig.searchForm.data.endTime = null;
      }
      const { data } = await exportFile('/api/stallBotPreorder/export', this.tableConfig.searchForm.data).finally(() => {
        this.loading = false;
      });

      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async handleQuery() {
      this.handleClear();
      if (this.tableConfig.searchForm.date) {
        let date = this.tableConfig.searchForm.date;
        if (this.tableConfig.searchForm.time && this.tableConfig.searchForm.time.length > 0) {
          this.tableConfig.searchForm.data.startTime = date + " " + this.tableConfig.searchForm.time[0];
          this.tableConfig.searchForm.data.endTime = date + " " + this.tableConfig.searchForm.time[1];
        }
        else {
          this.tableConfig.searchForm.data.startTime = date + " 00:00:00";
          this.tableConfig.searchForm.data.endTime = date + " 23:59:59";
        }
      }
      else {
        this.tableConfig.searchForm.data.startTime = null;
        this.tableConfig.searchForm.data.endTime = null;
      }
      this.$refs.gridTable.fetchData(false, this.handleCallback);
    },
    handleCallback(datas) {
      //自动选择
      if (this.autoSelectNext && this.$refs.gridTable.tableData.length > 0) {
        const firstRow = this.$refs.gridTable.tableData[0];
        this.$nextTick(() => {
          this.selectSameName(firstRow);
          this.handleAnalyse();
        });
      }

      //合计数据
      this.statistics.supplierCount = 0;
      this.statistics.preorderCount = 0;
      this.statistics.purchaserCount = 0;
      this.statistics.totalCount = 0;

      let _this = this;
      let suppliers = [];
      let purchasers = [];
      datas.forEach(function (data) {
        if (!suppliers.includes(data.SupplierName)) {
          _this.statistics.supplierCount += 1;
          suppliers.push(data.SupplierName);
        }

        const arr = data.PurchaserNames.split(',');
        for (const item of arr) {
          if (!purchasers.includes(item)) {
            purchasers.push(item);
            _this.statistics.purchaserCount += 1;
          }
        }

        _this.statistics.preorderCount += 1;
        _this.statistics.totalCount += data.TotalQty;
      });
    },
    handleTabClick(tab) {
      switch (tab.name) {
        case "checking":
          this.tableConfig.searchForm.data.state = 0;
          break;
        case "checked":
          this.tableConfig.searchForm.data.state = 1;
          break;
        case "deleted":
          this.tableConfig.searchForm.data.state = 2;
          break;
      }
      this.handleQuery();
    },
    selectSameName(row) {
      const _this = this;
      this.$refs.gridTable.tableData.forEach(function (data) {
        if (row.SupplierName == data.SupplierName && !_this.tableConfig.searchForm.data.single) {
          _this.$refs.gridTable.$refs.gridTable.toggleRowSelection(data, true);
        }
      });
    },
    selectionChange(selection) {
      this.selectRows = selection;
      if (this.selectRow) {
        let row = this.selectRow;
        this.selectRow = null;
        if (selection.some(data => data.Id == row.Id)) {
          this.selectSameName(row);
        }
        this.handleAnalyse();
      } else {
        this.handleClear();
      }
    },
    handleSelectAll() {
      this.handleAnalyse();
    },
    handleSelect(selection, row) {
      this.selectRow = row;
    },
    handleRowClick(row) {
      this.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      if (this.selectRows.some(data => data.Id == row.Id)) {
        this.selectSameName(row);
      }
      this.handleAnalyse();
    },
    handleClear() {
      this.selectSupplier = {
        Ids: [],
        SupplierId: '',
        SupplierName: '',
        Content: '',
        ConfigData: {
          qqSetting: {
            qq: undefined,
            botQQ: undefined,
            type: undefined,
          }
        },
        ErrorMsg: ''
      };
      this.detailList = [];
      this.statistics.purchaserCountEx = 0;
      this.statistics.totalCountEx = 0;
      this.detailActiveTab = "-1";
    },
    async handleAnalyse() {
      this.handleClear();
      const array = this.selectRows;
      for (var item of array) {
        this.statistics.purchaserCountEx += item["PurchaserCount"];
        this.statistics.totalCountEx += item["TotalQty"];
      }
      const ids = getColumnValue("Id", this.selectRows);
      if (!ids) return;
      this.detailLoading = true;
      const { data } = await submit("/api/stallBotPreorder/merge", {
        ids,
        groupBySupplier: this.tradeActiveTab == 'checking' ? true : this.groupBySupplier
      });
      let postfix = this.$store.state.routes.systemSetting.PreorderPostfix;
      postfix = postfix ? postfix : '【云果仓自动报单】';
      data.forEach(t => { t.Content = t.Content + '\n\n' + postfix; });
      this.detailList = data.sort(function (a, b) {
        if (a.SupplierName !== b.SupplierName) return a.SupplierName < b.SupplierName ? -1 : 1;
        return 0;
      });
      if (data.length > 0) {
        this.detailTabClick(0);
      }
      this.detailLoading = false;
    },
    handleChangeState(state) {
      const ids = getColumnValue("Id", this.selectRows);
      if (!ids) return;

      const action = state !== 0 ? "删除" : "撤回";
      this.$confirm(`您确定要${action}当前的报单内容?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await submit("/api/stallBotPreorder/changeState", {
          state,
          ids,
        });
        if (result.success) {
          this.$message({
            type: "success",
            message: `${action}成功!`,
          });
          this.handleQuery();
        }
      });
    },
    clearSelectTab(command) {
      var that = this;
      if (command == 'select') {
        let selectSupplierId = this.selectSupplier.SupplierId;
        var newDetailList = this.detailList.filter(x => x.SupplierId != selectSupplierId);
        if (this.selectSupplier.Ids.length > 0) {
          for (let i = this.$refs.gridTable.tableData.length - 1; i >= 0; i--) {
            if (this.selectSupplier.Ids.includes(this.$refs.gridTable.tableData[i].Id)) {
              this.$refs.gridTable.tableData.splice(i, 1);
            }
          }
        }
        this.$nextTick(() => {
          that.detailList = newDetailList;
          if (newDetailList.length > 0) {
            that.detailTabClick(0);
          } else {
            this.handleClear();
          }
        });
      } else {
        for (let i = this.$refs.gridTable.tableData.length - 1; i >= 0; i--) {
          if (this.$refs.gridTable.selectRows.includes(this.$refs.gridTable.tableData[i])) {
            this.$refs.gridTable.tableData.splice(i, 1);
          }
        }
        this.handleClear();
      }
      this.$nextTick(() => {
        if (that.$refs.gridTable.tableData.length <= 0) {
          that.handleQuery();
        }
      });
    },
    async handleCopy(command, e) {
      let ids = null;
      let content = '';
      if (command == 'select') {
        ids = this.selectSupplier.Ids.join(',');
        content = this.selectSupplier.Content;
      } else {
        ids = getColumnValue("Id", this.selectRows);
        for (let i = 0; i < this.detailList.length; i++) {
          let item = this.detailList[i];
          if (i > 0) {
            content += '\r\n\r\n\r\n\r\n';
          }
          content += item.SupplierName + '\r\n' + item.Content;
        }
      }
      if (!ids) return;
      clipboard(content, e);
      await submit("/api/stallBotPreorder/changeState", { state: 1, ids });
      this.clearSelectTab(command);
    },
    async handleSend(command, e) {
      let ids = null;
      if (command == 'select') {
        ids = this.selectSupplier.Ids.join(',');
      } else {
        ids = getColumnValue("Id", this.selectRows);
      }
      if (!ids) return;
      var list = [];
      if (command == 'select') {
        list.push(this.selectSupplier);
      } else {
        list = this.detailList;
      }
      this.sendFlag = true;
      var errors = [];
      var successIds = [];
      for (let i = 0; i < list.length; i++) {
        var supplier = list[i];
        try {
          let sendRes = await this.handleSendItem(supplier);
          if (sendRes) {
            successIds = [...successIds, ...supplier.Ids];
          }
        } catch (error) {
          errors.push("发送给供应商【" + supplier.SupplierName + "】失败，原因：" + error);
        }
      }
      this.sendFlag = false;
      if (successIds.length > 0) {
        let resultMsg = '发送成功';
        if (errors.length > 0) {
          resultMsg += '，部分失败<br/>' + errors.join('<br/>');
        }
        this.$message({ type: 'success', dangerouslyUseHTMLString: true, message: resultMsg });
        await submit("/api/stallBotPreorder/changeState", { state: 1, ids: successIds.join(',') });
        this.clearSelectTab(command);
      } else if (errors.length > 0) {
        this.$message({ type: 'error', dangerouslyUseHTMLString: true, message: errors.join('<br/>') });
      }
    },
    async handleSendItem(supplier) {
      if (supplier.ErrorMsg != '') {
        throw supplier.ErrorMsg;
      }
      if (!supplier.ConfigData.qqSetting.qq) {
        throw "供应商QQ未配置，无法发送！";
      }
      if (!supplier.ConfigData.qqSetting.botQQ) {
        throw "联系供应商QQ未配置，无法发送！";
      }
      let content = supplier.Content;
      return await botSend("text", content, {
        botType: 0,
        botUid: supplier.ConfigData.qqSetting.botQQ,
        senderUid: supplier.ConfigData.qqSetting.qq,
        messageType: supplier.ConfigData.qqSetting.type,
      });
    },
    handleSupplierSetting() {
      if (!this.selectSupplier.SupplierId) {
        return;
      }
      var formValue = {
        SupplierID: this.selectSupplier.SupplierId,
        qq: this.selectSupplier.ConfigData && this.selectSupplier.ConfigData.qqSetting ? this.selectSupplier.ConfigData.qqSetting.qq : null,
        botQQ: this.selectSupplier.ConfigData && this.selectSupplier.ConfigData.qqSetting ? this.selectSupplier.ConfigData.qqSetting.botQQ : null,
        type: this.selectSupplier.ConfigData && this.selectSupplier.ConfigData.qqSetting ? this.selectSupplier.ConfigData.qqSetting.type : null,
        market: this.selectSupplier.Market,
        noCustom: true,
      };
      this.$refs.editSupplier.init(formValue, '[' + this.selectSupplier.SupplierName + ']供应商设置');
    },
    async handleSupplierSettingSave(settingValue) {
      this.selectSupplier.Market = settingValue.market;
      this.selectSupplier.ConfigData.qqSetting.qq = settingValue.qq;
      this.selectSupplier.ConfigData.qqSetting.type = settingValue.type;
      this.selectSupplier.ConfigData.qqSetting.botQQ = settingValue.botQQ;
      await submit("/api/purchase/supplier/editSend", {
        SupplierID: this.selectSupplier.SupplierId,
        ConfigData: this.selectSupplier.ConfigData,
        Market: this.selectSupplier.Market,
      });
      this.$baseMessage("保存成功", "success");
      this.detailTabClick();
    },
    async getBotList() {
      const { data } = await submit("/api/stallBotAccount/getList");
      this.botQQList = data;
    },
    async handleGetSetting() {
      const { data } = await submit("/api/stallBotPreorder/getSetting");
      if (data) {
        this.settingForm = data;
      }
      this.settingVisible = true;
    },
    async saveSettingChange() {
      this.settingForm.ID = await submit("/api/stallBotPreorder/changeSetting", this.settingForm);
      this.settingVisible = false;
    },
  },
};
</script>
<style>
.war-message {
  z-index: 3000 !important;
}
</style>
<style lang="scss" scoped>
#order-content {
  #trade {
    ::v-deep {
      .el-input {
        width: 150px;
      }

      .el-date-editor.el-input {
        width: 130px;
      }

      .el-date-editor--timerange.el-input__inner {
        width: 120px;
      }

      .el-icon-time,
      .el-range__close-icon {
        display: none;
      }

      .el-switch {
        &:last-child {
          margin-right: 0;
        }
      }

      .el-footer {
        font-size: 15px;
        font-weight: bold;
        line-height: 40px;

        span {
          display: inline-block;
          width: 25%;

          &:last-child {
            width: calc(25% - 10px);
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
  }

  #detail {
    .el-textarea {
      height: 100%;
      width: calc(100%);
    }

    ::v-deep {
      .el-textarea__inner {
        height: 100%;
      }

      .el-footer {
        font-size: 15px;
        font-weight: bold;
        line-height: 40px;

        div {
          display: inline-block;
          width: 50%;

          &>span {
            display: inline-block;
            width: 50%;
          }

          .el-switch__label {
            width: auto;
            margin-right: 10px;

            .el-switch__label {
              width: auto;
            }
          }

          &:last-child {
            text-align: right;
          }

          &>span.btn_popover {
            display: inline !important;
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .report-dialog {

    .el-icon-time,
    .el-range__close-icon {
      display: none;
    }

    .el-date-editor--timerange.el-input__inner {
      padding-left: 10px;
      width: 180px;
    }

    .el-input__inner {
      padding-left: 15px;
      width: 100px;
    }
  }

  .el-main,
  .el-footer {
    border-top: 0px !important;
  }

  #detail {
    .el-radio-button {
      border-bottom: 2px solid #e4e7ed;
      height: 40px;
      line-height: 40px;

      .el-radio-button__inner {
        border: 0px;
        font-size: 14px;
        font-weight: 500;
        background-color: #ffffff;
        color: #303133;
      }
    }

    .el-radio-button.is-active {
      border-bottom: 2px solid #409eff;

      .el-radio-button__inner {
        border: 0px;
        background-color: #ffffff;
        color: #409EFF;
        font-size: 14px;
        font-weight: 500;
        ;
        -webkit-box-shadow: 0px 0 0 0 #409EFF;
      }
    }
  }


}
</style>
